import React, { Component, useState } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import { Flex, Image, Text, Button, Divider, MenuButton, Avatar, listItemClassName } from '@fluentui/react-northstar';
import './NavMenu.css';
import { LegendItemHoverEvent } from '@progress/kendo-react-charts';



function AuthNavItem(props) {
  // If authenticated, return a dropdown with the user's info and a
  // sign out button
  if (props.isAuthenticated) {
    return (
      <MenuButton
      trigger={<Button icon={<Avatar name={props.account.name} />} text content={props.account.name} iconPosition="after" />}
      menu={[
        <Text content={props.account.name} />,
        <Text content={props.account.username} />,
        <Button className="btn-left-align" text active={props.themeString === 'default'} onClick={()=> props.themeHandler('default')} content="Default"/>,
            <Button className="btn-left-align" text active={props.themeString === 'dark'} onClick={()=> props.themeHandler('dark')} content="Dark"/>,
            <Button className="btn-left-align" text active={props.themeString === 'contrast'} onClick={()=> props.themeHandler('contrast')} content="Contrast"/>,
        // {
        //   content: 'Theme',
        //   menu= {[
        //     <Button className="btn-left-align" text active={props.themeString === 'default'} onClick={()=> props.themeHandler('default')} content="Default"/>,
        //     <Button className="btn-left-align" text active={props.themeString === 'dark'} onClick={()=> props.themeHandler('dark')} content="Dark"/>,
        //     <Button className="btn-left-align" text active={props.themeString === 'contrast'} onClick={()=> props.themeHandler('contrast')} content="Contrast"/>,
        //     ]}
        // },
        <Button className="btn-left-align" text onClick={props.authButtonMethod} content="Sign Out"/>,
      ]}
      on="click"
    />
    );
  }
  // Not authenticated, return a sign in link
  return (
    <Button
      text
        onClick={props.authButtonMethod}
        className="btn-link nav-link border-0"
        color="link"
      >
        Sign In
      </Button>
  );
}



export const NavMenu = (props) => {  
  const displayName = NavMenu.name;
  const {collapsed, setCollapsed} = useState(false);
  const location = useLocation();
  const {  pathname  } = location;
 
  let SettingsAuthURL = "/settings/servers";

  return (
      <header className='navigation'>
          <nav>
              <Flex gap="gap.medium" className="w-100" hAlign="stretch">
                  <Flex.Item size="size.quarter" align="stretch">
                      <Link className='logo' to="/" style={{marginLeft : '14px'}}>
                          <Image alt="TollringHubWebApp" styles={{height:"30px;margin-top:5px;"}} src="/logo192.png"  />
                      </Link>
                  </Flex.Item>
                  <Flex.Item align="end" className='w-100' >
                      <Flex gap="gap.medium" className="w-100 nav-menu-list" hAlign="end">
                          { props.user?.IsDashboardAllowed ? 
                            props.user?.PermissionGroupId == "15" ?
                            <div className={pathname && pathname.toLowerCase().startsWith('/dashboards/') ? 'nav-menu nav-menu-active' : 'nav-menu'}><Link to= "/dashboards/monitoring/infra/alarms">Dashboards</Link></div> 
                            :
                            props.user?.Permissions?.some((p) => p == "Dashboards.Read" || p == "Dashboards.ReadWrite" || p == "Dashboards.BusinessPerformance.Read" || p == "Dashboards.BusinessPerformance.ReadWrite") ?
                            <div className={pathname && pathname.toLowerCase().startsWith('/dashboards/') ? 'nav-menu nav-menu-active' : 'nav-menu'}><Link to= "/dashboards/businessPerformance">Dashboards</Link></div> 
                            :
                            props.user?.Permissions?.some((p) => p == "Dashboards.Read" || p == "Dashboards.ReadWrite" || p == "Dashboards.Monitoring.Read" || p == "Dashboards.Monitoring.ReadWrite") ?
                                          <div className={pathname && pathname.toLowerCase().startsWith('/dashboards/') ? 'nav-menu nav-menu-active' : 'nav-menu'}><Link to="/dashboards/monitoring/infra/summary">Dashboards</Link></div> 
                            :
                            props.user?.Permissions?.some((p) => p == "Dashboards.Read" || p == "Dashboards.ReadWrite" || p == "Dashboards.Revenue.Read" || p == "Dashboards.Revenue.ReadWrite") ?
                            <div className={pathname && pathname.toLowerCase().startsWith('/dashboards/') ? 'nav-menu nav-menu-active' : 'nav-menu'}><Link to="/dashboards/revenue">Dashboards</Link></div> 
                            : <></> : <></>
                          }

                          { props.user?.IsSettingsAllowed  ? 
                            <div className={pathname && pathname.toLowerCase().startsWith('/settings/') ? 'nav-menu nav-menu-active' : 'nav-menu'}><Link to={SettingsAuthURL}>Settings</Link></div> : <></>
                          } 
                          {
                            props.user?.IsAccessBlocked == false ?
                            <div className={pathname && pathname.toLowerCase().startsWith('/tools/') ? 'nav-menu nav-menu-active' : 'nav-menu'}><Link to="/tools">Tools</Link></div>
                            : <></>
                          }
                          <div className='nav-menu' style={{marginRight: '10px'}}>
                <AuthNavItem
                  isAuthenticated={props.isAuthenticated}
                  authButtonMethod={props.isAuthenticated
                      ? props.signOut
                      : props.signIn}
                  account={props.account}
                  themeString={props.themeString}
                  themeHandler={props.themeHandler}
                />
              </div>
            </Flex>
          </Flex.Item>
        </Flex>
      </nav>
    </header>
  );
}
