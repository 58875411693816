import React, { useState } from 'react'
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, Loader,Tooltip, Text, Flex } from '@fluentui/react-northstar';
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { CellIncidentHistory,CellServerRuleBreached, CellServerGraph, CellAccessIcon, CellDateTime, CellIncidentStatus, CellIncidentOwner , CellColTitle,CellComments, rowRender} from '../../../../shared/appcomponents/custom-grid-cells';
import { INCIDENTLEVELS } from '../../../../shared/appcomponents/enums';
import MonitoringService from '../../../../services/monitoringservice';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from '@progress/kendo-data-query';
import { WindowContext } from "../../../../shared/contexts/Context";
import { useSearchParams, useParams } from 'react-router-dom';
import { OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';

const InfraAlarm = (props) => {
    const { id } = useParams();
    const [incidents, setIncidents] = useState(null);
    const [IncidentStatuses, setIncidentStatuses] = useState(null);
    const [AppUsers, setAppUsers] = useState(null);

    const [isLoaded, setLoaded] = React.useState(false);

    const [isUpdating, setUpdating] = React.useState(false);
    const [searchParams] = useSearchParams();

   
    React.useEffect(() => {
      props.incidents?.Table?.map(m => m.IsUpdating = false);
      setIncidents(props.incidents?.Table);
      setAppUsers(props.incidents?.AppUsers);
      setIncidentStatuses(props.incidents?.IncidentStatuses);
      setLoaded(true); 
    }, [props.incidents]);    

    const [dataState, setDataState] = React.useState({
      sort: [
        {
          field: "IncidentTimestampUTC",
          dir: "desc",
        },
      ],
      take: 10,
      skip: 0,
    });

    const handleFilterUpdate = (appliedFilters) => {
      setLoaded(false);    
      setIncidents(null);    
      setIncidentStatuses(null); 
     setAppUsers(null);
                
      props.acquireToken((token) => {
          let _params = {
              instanceId: id ? id : -1,
              incidentLevelId : INCIDENTLEVELS[props.alarmType],
              appliedFilters: appliedFilters
          }
          if (token) {
            MonitoringService.GetInfraIncidents(token, _params).then((response) => {
              if(response && response.status==200){
                response=response.data;
                setAppUsers(response.AppUsers);
                setIncidentStatuses(response.IncidentStatuses);
                response.Table?.map(m => m.IsUpdating = false);
                setIncidents(response.Table);
              }
              else if(response && response.status==401){
                props.viewStatus.InformChildPageStatus(response.status);
                }
                else {
                  props.viewStatus.InformChildPageStatus(500);
                }
    
              setLoaded(true);
            });
          }
      })
  }
  
    const filterGridData = (value) => {
      let filter = {
        logic: 'or',
        filters: [
          { field: 'ServerName', operator: 'contains', value: value },
          { field: 'ServerType', operator: 'contains', value: value }
        ],
      };
      setDataState({ ...dataState, filter: filter });
  };


  const CellIncidentStatusCol = (prop) => {
    return <CellIncidentStatus {...prop} user={props.user} incidentStatus={IncidentStatuses}
      onChange={() => {
        props.acquireToken((token) => {
        setUpdating(true); 
          prop.dataItem.IncidentOwner = props.account.username;
          UpdateIncedent(token, prop.dataItem);
        })}}
    />
  }

  const CellIncidentOwnerCol = (prop) => {
    return <CellIncidentOwner   {...prop} user={props.user} appUsers={AppUsers}
    onChange={() => {props.acquireToken((token) => {
        setUpdating(true); 
        UpdateIncedent(token, prop.dataItem);
      })}}
  />    
  }

  const UpdateIncedent = (token, dataItem) => {
   
   
    let _params = {
      IncidentSection: 1,
      IncidentId: dataItem.IncidentId,
      IncidentOwner: dataItem.IncidentOwner,
      IncidentStatusId: dataItem.IncidentStatusId
    }

   

    MonitoringService.UpdateIncident(token, _params).then((response)=>{
      if(response && response.status==200){
        response=response.data;
     
        if(dataItem.IncidentStatusId===3){
          let array = [...incidents];
            let index= array.findIndex(element => element.IncidentId === dataItem.IncidentId)
            if (index !== -1) {
                array.splice(index, 1);
                setIncidents(array);
              }
        }
        setUpdating(false);
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
      }
      else if(response && response.status==401){
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      dataItem.IsUpdating=false;    
      if(dataItem.IncidentStatusId!==3){
      let arr = [...incidents];
      setIncidents(arr);  }
    }); 

  }

  const RenderTooltipCell = (props) => {
    return (
      <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Tooltip
          content={props.dataItem[props.field]}
          trigger={<span>{props.dataItem[props.field]}</span>}
        />
      </td>
    );
  };

  const RefeshData = (appliedFilters) => {
    setLoaded(false);    
    setIncidents(null);    
    setIncidentStatuses(null); 
    setAppUsers(null);
   
              
    props.acquireToken((token) => {
        let _params = {
            instanceId: id ? id : -1,
            incidentLevelId : INCIDENTLEVELS[props.alarmType],
            appliedFilters: appliedFilters
        }
        if (token) {
          MonitoringService.GetInfraIncidents(token, _params).then((response) => {
            if(response && response.status==200){
              response=response.data;
              setAppUsers(response.AppUsers);
              setIncidentStatuses(response.IncidentStatuses);
              response.Table?.map(m => m.IsUpdating = false);
              setIncidents(response.Table);
            }
            else if(response && response.status==401){
              props.viewStatus.InformChildPageStatus(response.status);
              }
              else {
                props.viewStatus.InformChildPageStatus(500);
              }
  
            setLoaded(true);
          });
        }
    })
}

  const CellIncidentHistoryCol = (prop) => {
     return (
      
         <CellIncidentHistory {...prop} IncidentSection={1} acquireToken={props.acquireToken} />
       
     );
  }

  const CellCommentsCol = (prop) => {
     return (
       <td style={!prop.text ? { textAlign: "center" } : {}}>
         {prop.text && <span>{prop.text}&nbsp;</span>}
         <CellComments {...prop} user={props.user} IncidentSection={1} acquireToken={props.acquireToken} />
       </td>
     );
  }

   const expandChange = (event) => {
     let newData = incidents.map((item) => {
       if (item.IncidentId === event.dataItem.IncidentId) {
         item.expanded = !event.dataItem.expanded;
       }
       return item;
     });
     setIncidents(newData);
   };

   const DetailComponentSm = (props1) => {
     const dataItem = props1.dataItem;
     return (
       <table>
         <tbody>
           <tr>
             <td>&nbsp;&nbsp;IncidentId: {dataItem.IncidentId}</td>
             <td>&nbsp;&nbsp;Server Name: {dataItem.ServerName}</td>
           </tr>
           <tr>
             <td>&nbsp;&nbsp;Server Type: {dataItem.ServerType}</td>
             <td>&nbsp;&nbsp;Server Rule Breached: {dataItem.ServerRuleBreached}</td>
           </tr>
           <tr>
             <td>
               <CellAccessIcon
                 {...props1}
                 field={"RDPAccess"}
                 text={"RDP Access:"}
               />
             </td>
             <td>
               <CellServerGraph
                 {...props1}
                 field={"ServerGraph"}
                 text={"Server Graph:"}
               />
             </td>
           </tr>
           <tr>
             <td>
               <CellCommentsCol
                 {...props1}
                 field={"IncidentId"}
                 text={"Comments:"}
               />
             </td>
             <td>
               <CellIncidentHistoryCol
                 {...props1}
                 field={"IncidentId"}
                 text={"History:"}
               />
             </td>
           </tr>
           <tr>
             <td>
               <CellDateTime
                 {...props1}
                 field={"ClearedTimeStampUTC"}
                 text={"Cleared TimeStamp UTC:"}
               />
             </td>
             <td>&nbsp;&nbsp;Owner: {dataItem.DisplayName}</td>
           </tr>
         </tbody>
       </table>
     );
   };

    const DetailComponentLg = (props1) => {
      return (
        <table>
          <tbody>
            <tr>
              <td>
                <CellAccessIcon
                  {...props1}
                  field={"RDPAccess"}
                  text={"RDP Access:"}
                />
              </td>
              <td>
                <CellCommentsCol
                  {...props1}
                  field={"IncidentId"}
                  text={"Comments:"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    };


  return <WindowContext.Consumer>
      {(context) =>(
    <>
    <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
                                <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                                  defaultAppliedFilter={
                                    {filter: 'Instance', operator: '==', value: id}
                                  }
                                //defaultAppliedFilter={{filter: 'Instance', operator: '==', value: id}} 
                                elegibleFilters={['Instance', 'Server', 'ServerType','Period', 'Platform', 'Owner', 'IncidentStatus']} />
                                <Flex.Item push>
                                    <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                                    <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                                    </div>
                                </Flex.Item>
                            </Flex>
                            <div className='w-100 no-bg'>
                            {
                                isLoaded && incidents ?
                                (
                                    <KendoGrid
                                    style={{
                                      height: "456px",
                                    }}
                                    // pageable={true}
                                    pageable={{
                                      buttonCount: 3
                                    }}
                                    sortable={true}
                                    data={process(incidents, dataState)}
                                    {...dataState}
                                    onDataStateChange={(e) => {
                                      setDataState(e.dataState);
                                    }}
                                    detail={
                                     context.mediaCategory.sm ? DetailComponentSm : DetailComponentLg
                                    }
                                    expandField="expanded"
                                    onExpandChange={expandChange}
                                    rowRender={rowRender}
                                    >
                                      {!context.mediaCategory.sm && (
                                        <Column field="IncidentId" title="Ref#" width={100} locked={true} />
                                        )}
                                          <Column field="IncidentTimestampUTC" title="Timestamp UTC" cell={CellDateTime} width={200} locked={true}  />
                                        {!context.mediaCategory.sm && (
                                          <Column field="ServerName" title="Server Name" cell={RenderTooltipCell} width={200} locked={true}  />
                                        )}

                                        {!context.mediaCategory.sm && (
                                          <Column field="ServerType" title="Server Type" width={200}/>
                                        )}

                                        {!context.mediaCategory.sm && (
                                          <Column field="ServerRuleBreached" title="Server Rule Breached" cell={CellServerRuleBreached} width={300}/>
                                        )}

                                        {!context.mediaCategory.sm && (
                                          <Column field="ServerGraph" title="Server Graph"  cell={CellServerGraph} width={120}filterable={false} sortable={false} />
                                         )}

                                        {!context.mediaCategory.sm && (
                                          <Column field="IncidentId" title="History" cell={CellIncidentHistoryCol} width={70}filterable={false} sortable={false} />
                                         )}
                                        <Column field="IncidentStatusId" title="Status" cell={CellIncidentStatusCol} width={150} />
                                        <Column field="IncidentOwner" title="Owner" cell={CellIncidentOwnerCol}  width={200} />
                                        {!context.mediaCategory.sm && (
                                          <Column field="IncidentId" title={<CellColTitle title="Comments" />} width={110} cell={CellCommentsCol} filterable={false} sortable={false} />  
                                         )}
                                        
                                        {/* {!context.mediaCategory.sm && (
                                        <Column field="RDPAccess" title="RDP Access" cell={CellAccessIcon} />)} */}
                                        
                                        {/* {!context.mediaCategory.sm && (
                                        <Column field="IncidentId" title="Comments"  cell={CellCommentsCol} />)} width={110} */}
                                        
                                    </KendoGrid>
                                )
                                :
                                <Loader />
                            }
                            </div>  
    
    </>
  )}
  </WindowContext.Consumer>
};


export { InfraAlarm }

