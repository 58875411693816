import React from 'react'
import { Card, Flex, Text, Button, Tooltip, Toolbar, Grid } from '@fluentui/react-northstar'
import { BroadcastIcon, BellIcon, PlugsIcon, AppsIcon, TranscriptIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useSearchParams, useLocation, useOutletContext } from "react-router-dom";
import { setRouteInLocalStorage } from '../../../shared/contexts/Context';
import MonitoringService from '../../../services/monitoringservice';
import './monitoring.scss';
import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';

const MonitoringDashboard = (props) => {
    const [viewArea, setViewArea] = React.useState("");
    const [area, setArea] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [monitoringHome, setMonitoringHome] = React.useState(null);
    const location = useLocation();
    let navigate = useNavigate(); 
    const routeChange = (path, varea, area) =>{
        setViewArea(varea);
        setArea(area);
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
    }
    React.useEffect(() => {
        let routeParts = window.location.pathname.split('/');
        let varea = routeParts[4]; //searchParams.get('type');
        let area = routeParts[3];
        setArea(area);
        if(varea){
            setViewArea(varea);
        }
        else{
            if(window.location.pathname.includes('summary')){
                setViewArea('summary');
            }
            else if(window.location.pathname.includes('detail')){
              setViewArea("detail");
            }
            else{
              setViewArea("overview");
            }
        }
    }, [location]);

    React.useEffect(() => {
      props.acquireToken(authSuccessCallback);  
    }, []);

    const authSuccessCallback = (token) => {
      if (token) {
        MonitoringService.GetMonitoringHome(token).then((response) => {
          if(response && response.status==200){
            response=response.data;
            let _configRules = {};
            Object.keys(response.ConfigRules).map((v) => { _configRules[v] = JSON.parse(response.ConfigRules[v])});
            setRouteInLocalStorage('configRules', JSON.stringify(_configRules));
            setMonitoringHome(response);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }

          setLoading(false);
        });
      }
    };

    return(
        <>
        <div className='dashboard-top'>
            <div className='row'>
                <div className='col-sm-12 col-xs-12 mb-2'>
                    <Grid columns={3} style={{gridGap: '15px'}}>
                    <Card className={`card card-infra ${area == 'infra' ? 'selected' : ''}`} elevated >
                        <Card.Body>
                            <Toolbar
                            items={[
                                {
                                  key: 'custom-text',
                                  content: <Text content="Infrastructure" />,
                                  kind: 'custom',
                                  className: 'heading',
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-infra-incident',
                                  content: <Button className={`${area == 'infra' && viewArea == 'alarms' ? 'selected' : ''}`} icon={<div className='incident-counts'><span className='critical'>{!loading && monitoringHome ? monitoringHome.InfraIncidentCounter?.Critical : '-'}</span><span className='warning'>{!loading && monitoringHome ? monitoringHome.InfraIncidentCounter?.Warning : '-'}</span></div>} content="Incidents" iconPosition="after"  onClick={() => routeChange('/dashboards/monitoring/infra/alarms?type=critical', 'alarms', 'infra')} />,
                                  focusable: true,
                                  kind: 'custom',
                                  className: `btn multi ${area == 'infra' && viewArea == 'alarms' ? 'selected' : ''}`,
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-infra-detail',
                                  kind: 'custom',
                                  content:  <Tooltip pointing iconOnly trigger={<Button className={`${area == 'infra' && viewArea == 'detail' ? 'selected' : ''}`} icon={<BroadcastIcon />} text onClick={() => routeChange('/dashboards/monitoring/infra/detail', 'detail', 'infra')} />} content="Detail" />,
                                  fitted: 'horizontally',
                                  className: `card-actions ${area == 'infra' && viewArea == 'detail' ? 'selected' : ''}`,
                                },
                                {
                                  key: 'custom-infra-summary',
                                  kind: 'custom',
                                  content: <Tooltip pointing iconOnly trigger={<Button className={`${area == 'infra' && viewArea == 'summary' ? 'selected' : ''}`} icon={<TranscriptIcon />} text onClick={() => routeChange('/dashboards/monitoring/infra/summary', 'summary', 'infra')} />} content="Summary" />,
                                  fitted: 'horizontally',
                                  className: `card-actions ${area == 'infra' && viewArea == 'summary' ? 'selected' : ''}`,
                                },
                              ]}
                            >
                            </Toolbar>
                            <BroadcastIcon outline rotate={0} size="largest" />
                        </Card.Body>
                    </Card>
                    <Card className={`card card-portal ${area == 'portal' ? 'selected' : ''}`} elevated >
                        <Card.Body>
                        <Toolbar
                            items={[
                                {
                                  key: 'custom-text',
                                  content: <Text content="Data" />,
                                  kind: 'custom',
                                  className: 'heading',
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-portal-incident',
                                  content: <Button className={`${area == 'portal' && viewArea == 'alarms' ? 'selected' : ''}`} icon={<div className='incident-counts'><span className='critical'>{!loading && monitoringHome ? monitoringHome.DataIncidentCounter?.Critical : '-'}</span><span className='warning'>{!loading && monitoringHome ? monitoringHome.DataIncidentCounter?.Warning : '-'}</span></div>} content="Incidents" onClick={() => routeChange('/dashboards/monitoring/portal/alarms?type=critical', 'alarms', 'portal')} iconPosition="after" />,
                                  focusable: true,
                                  kind: 'custom',
                                  className: `btn multi ${area == 'portal' && viewArea == 'alarms' ? 'selected' : ''}`,
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-portal-detail',
                                  kind: 'custom',
                                  content:  <Tooltip pointing trigger={<Button className={`${area == 'portal' && viewArea == "detail" ? 'selected' : ''}`} icon={<AppsIcon />} text iconOnly onClick={() => routeChange('/dashboards/monitoring/portal/detail?state=critical', 'detail', 'portal')} />} content="Detail" />,
                                  fitted: 'horizontally',
                                  className: `card-actions ${area == 'portal' && viewArea == 'detail' ? 'selected' : ''}`,
                                },
                                {
                                  key: 'custom-portal-summary',
                                  kind: 'custom',
                                  content: <Tooltip pointing trigger={<Button className={`${area == 'portal' && viewArea == 'summary' ? 'selected' : ''}`} icon={<TranscriptIcon />} text iconOnly onClick={() => routeChange('/dashboards/monitoring/portal/summary', 'summary', 'portal')} />} content="Summary" />,
                                  fitted: 'horizontally',
                                  className: `card-actions ${area == 'portal' && viewArea == 'summary' ? 'selected' : ''}`,
                                },
                              ]}
                            >
                            </Toolbar>
                            <AppsIcon outline rotate={0} size="largest" />
                        </Card.Body>
                    </Card>
                    <Card className={`card card-service ${area == 'service' ? 'selected' : ''}`} elevated >
                        <Card.Body>
                        <Toolbar
                            items={[
                                {
                                  key: 'custom-text',
                                  content: <Text content="Service" />,
                                  kind: 'custom',
                                  className: 'heading',
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-service-incident',
                                  content: <Button className={`${area == 'service' && viewArea == 'alarms' ? 'selected' : ''}`} icon={<div className='incident-counts'><span className='black'>{!loading && monitoringHome ? monitoringHome.ServiceHeartbeatCounter?.heartbeat : '-' }</span><span className='critical'>{!loading && monitoringHome ? monitoringHome.ServiceIncidentCounter?.Critical : '-' }</span><span className='warning'>{!loading && monitoringHome ? monitoringHome.ServiceIncidentCounter?.Warning : '-' }</span></div>} content="Incidents" iconPosition="after" onClick={() => routeChange('/dashboards/monitoring/service/alarms?type=critical', 'alarms', 'service')} />,
                                  focusable: true,
                                  kind: 'custom',
                                  className: `btn multi ${area == 'service' && viewArea == 'alarms' ? 'selected' : ''}`,
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-service-detail',
                                  kind: 'custom',
                                  content: <Tooltip pointing trigger={<Button className={`${area == 'service' && viewArea == 'detail' ? 'selected' : ''}`} icon={<PlugsIcon />} text iconOnly onClick={() => routeChange('/dashboards/monitoring/service/detail', 'detail', 'service')} />} content="Detail" />,
                                  fitted: 'horizontally',
                                  className: `card-actions ${area == 'service' && viewArea == 'detail' ? 'selected' : ''}`,
                                },
                                {
                                  key: 'custom-service-summary',
                                  kind: 'custom',
                                  content: <Tooltip pointing trigger={<Button className={`${area == 'service' && viewArea == 'summary' ? 'selected' : ''}`} icon={<TranscriptIcon />} text iconOnly onClick={() => routeChange('/dashboards/monitoring/service/summary', 'summary', 'service')}  />} content="Summary" />,
                                  fitted: 'horizontally',
                                  className: `card-actions ${area == 'service' && viewArea == 'summary' ? 'selected' : ''}`,
                                },
                              ]}
                            >
                            </Toolbar>
                            <PlugsIcon outline rotate={0} size="largest" />
                        </Card.Body>
                    </Card>
                    </Grid>
                </div>
            </div>
        </div>
        <div className='dashboard-details mb-3'>
            <div className='row'>
                <div className='col-sm-12'>
                    <Outlet />
                </div>
            </div>
        </div>
        </>
    )
}

export default MonitoringDashboard;